import React from "react"

import Header from '../components/header'
import Footer from '../components/footer'


export default class DataProcessingPolicy extends React.Component {

render(){
return(
<>  
  <div className="wrapper default ">
    <Header />
      
      <div id="primary" class="boxed-layout-header page-header header-small" data-parallax="active">
        <div class="container">
          <div class="row">
            <div class="col-md-10 col-md-offset-1 text-center">
              <h1 class="hestia-title ">Data Processing Policy</h1>
            </div>
          </div>
        </div>
        <div class="header-filter header-filter-gradient"></div>
      </div>
      <div class="main  main-raised ">
        <div class="blog-post ">
          <div class="container">
            <article id="post-132" class="section section-text">
              <div class="row">
                <div class="col-md-8 page-content-wrap  col-md-offset-2">
                  <div data-elementor-type="post" data-elementor-id="132" class="elementor elementor-132" data-elementor-settings="[]">
                    <div class="elementor-inner">
                      <div class="elementor-section-wrap">
                        <section data-id="fa25f18" class="elementor-element elementor-element-fa25f18 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-element_type="section">
                          <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-row">
                              <div data-id="cd468b8" class="elementor-element elementor-element-cd468b8 elementor-column elementor-col-100 elementor-top-column" data-element_type="column">
                                <div class="elementor-column-wrap  elementor-element-populated">
                                  <div class="elementor-widget-wrap">
                                    <div data-id="4fc91cf" class="elementor-element elementor-element-4fc91cf elementor-widget elementor-widget-text-editor" data-element_type="text-editor.default">
                                      <div class="elementor-widget-container">
                                        <div class="elementor-text-editor elementor-clearfix">
                                          <div style={{direction: 'ltr', textAlign: 'left'}}>
                                            <p>Data Processing PolicyThis Data Processing Policy<span dir="RTL"></span><span dir="RTL"></span><span dir="RTL"> </span>and its exhibits apply to the processing of Personal Data by the Company on behalf of a client in order to provide cloud services as agreed in the agreement with the Client.</p>
                                            <h1>Data Processing</h1>
                                            <ol>
                                              <li><span dir="LTR"></span>Client is the sole controller of the client’s personal information and Client agrees to the processing of Client’s personal data by the Prometheus Ltd (the “<b>Company</b>”) as set out in this policy. Client appoints the Company as Processor for the mean of processing Client’s personal data.</li>
                                              <li><span dir="LTR"></span>Categories of Data subjects, type of Client’s personal data and the processing activities are set out in the applicable Exhibit for the company’s applicable service, the duration of the processing of Client’s personal data corresponds with the duration of the service provided by the Company, the purpose of the processing of personal data reflects the provisions of the service provided to the client.</li>
                                              <li><span dir="LTR"></span>The Company will process Client’s personal data according to the scope of service provided to the Client by the Company, as set out in the agreement between the Company and client.</li>
                                              <li><span dir="LTR"></span>The Client will be the only point of contact for the Company and Client will be regarded as the only Controller of the Client’s personal data.</li>
                                              <li><span dir="LTR"></span>The Company will take any measure to comply with the European General Data Protection Regulation (“<b>GDPR</b>”) in respect of the services applicable to the Client. The Company will not be responsible for determining the applicable privacy protection legislation of the Client and Client approves that the Company’s service meets the requirements of such legislation. Client is responsible for the lawfulness of the processing of Client’s personal data. Client undertakes to not use the service if such service violates any applicable data protection legislation.</li>
                                            </ol>
                                            <h1>Sub-Processors</h1>
                                            <ol start="6">
                                              <li><span dir="LTR"></span>Company may engage with subcontractors to execute Company’s undertakings under the agreement with a Client. Company will ensure that all<span dir="RTL"></span><span dir="RTL"></span><span dir="RTL"> </span>subcontractors are taking the security measures as the Company with regard to Client’s personal data processed, or stored on third parties digital and physical infrastructures. A list of current subcontractors engaged with the Company is annexed to this Data Processing Policy. Company may update the list of subcontractors from time to time and without notice.</li>
                                              <li><span dir="LTR"></span>If a Client disapproves the use of a specific subcontractor used by the Company, it may inform the Company with a written notice, and Company will reply within reasonable time if that specific subcontractor may be replaced for the Client’s needs, or that Client may terminate the agreement with the Company effectively from Company’s respond.</li>
                                            </ol>
                                            <p></p>
                                            <h1>Cross-border data transfer and data processing</h1>
                                            <ol start="8">
                                              <li><span dir="LTR"></span>Company may use, for the purpose of its’ services, subcontractors and/or Sub-Processors which are established outside of the EU countries, by implementing the EU Standards Contractual Clauses and only to countries considered by the EU commission to have adequate protection of personal data.</li>
                                            </ol>
                                            <h1>Certification – Data Security</h1>
                                            <ol start="9">
                                              <li><span dir="LTR"></span>Company takes the required security measures on its platforms and applications. Client is responsible for executing security measures to protect Client’s personal data and to comply with privacy legislation in Client’s territory. The company implemented and maintained ISO 27001 standard.</li>
                                              <li><span dir="LTR"></span>The Company will take organizational measures and will conduct technical maintenance actions to ensure a level of security appropriate to the risk of the Company’s scope of service. The Company keeps its right to change and update those measures provided that the security and functionality of the service are not degraded.</li>
                                              <li><span dir="LTR"></span>Client confirms that the company’s security measures provide the Client appropriate level of protection for the Client’s personal data, in consideration with risks associated with the Client’s personal data.</li>
                                            </ol>
                                            <h1>Data Subjects Rights</h1>
                                            <ol start="12">
                                              <li><span dir="LTR"></span>Company will notify the Client of any requests from data subjects exercising their rights such as amending the data or deletion of the data or any other data subject right according to privacy legislation. Client will be responsible to respond to data subjects requests according to their rights. Client will have reasonable cooperation by the Company to comply with data subjects rights.</li>
                                              <li><span dir="LTR"></span>In case a data subject initiate legal proceedings against the Company for breaching its data subject’s rights, Client will indemnify the Company for any damages or expenses which may arise as a result of such legal proceedings only if the Company notified Client for the initiated legal proceedings by the data subject and Company allowed and assisted Client to prepare its defense.</li>
                                              <li><span dir="LTR"></span>Client has the right to claim from the Company amounts paid to data subjects for breaching its data subject rights caused due to Company’s breach of its obligation under GDPR.</li>
                                              <li><span dir="LTR"></span>The Company will not share Client’s personal data with third parties unless the Company instructed in writing by Client or required by law. Company will notify Client about disclosure requirements received from third parties and authorities, unless company was ordered otherwise.</li>
                                            </ol>
                                            <p><b><i> </i></b></p>
                                            <p><b><i> </i></b></p>
                                            <h1>Breach Notification</h1>
                                            <ol start="16">
                                              <li><span dir="LTR"></span>Company will notify Client as it become aware of personal data breach with regard to the Client’s personal data, controlled or processed by the Company or with regard to the services provided by the Company to Client.</li>
                                              <li><span dir="LTR"></span>Company will act with regard to Breach Notification to the Authorities, as it will be instructed to by the Client, or by any law which will apply on the Company in a specific personal data breach event.</li>
                                              <li><span dir="LTR"></span>The Company will investigate any Client’s personal data breach and will notify the Client<span dir="RTL"></span><span dir="RTL"></span><span dir="RTL"> </span>of such investigation’s results.</li>
                                              <li><span dir="LTR"></span>Client will notify the company of any personal data breach, which Client becomes aware of, and the Company will assist the Client to investigate the personal data breach, with regard to the services provided to the Client on the Company’s network infrastructure and platforms.</li>
                                            </ol>
                                            <h1>Confidentiality</h1>
                                            <ol start="20">
                                              <li><span dir="LTR"></span>The Company will verify that any of the Company’s employees and contractors which have access to Client’s personal data, signed with the Company a confidentiality agreement / undertaking with regard to Client’s personal data.</li>
                                            </ol>
                                            <h1>Data Deletion</h1>
                                            <ol start="21">
                                              <li><span dir="LTR"></span>Company does not retain personal information other than for the purpose of the services it provides. If for any reason Client wishes to modify or delete Client’s Personal Information request needs to be sent to:<a href="mailto:team@outicks.com">team@outicks.com</a>, and Company will make reasonable efforts to modify or delete any such Personal Information pursuant to any applicable privacy laws within reasonable time.</li>
                                              <li><span dir="LTR"></span>Unless instructed otherwise Company may retain Client’s Personal Information for a period of no longer than seven (7) years since Client’s last use of the Company Services.</li>
                                              <li><span dir="LTR"></span>Upon Client’s request to delete Personal Information, Company may depersonalize Client’s Personal Information, instead of deleting it, for the purpose of enhancing and improving the Company’s Services,</li>
                                              <li><span dir="LTR"></span>Some information may not be deleted in order to keep the integrity of the Company’s system, but the information will be stripped off any Personal Information linked to such information and having such data be anonymized.</li>
                                              <li><span dir="LTR"></span>Following a request to the Company from Client to<span dir="RTL"></span><span dir="RTL"></span><span lang="HE" dir="RTL"><span dir="RTL"></span><span dir="RTL"></span>:</span><span dir="LTR"></span><span dir="LTR"></span><span dir="LTR"></span><span dir="LTR"></span> (i) delete any Personal Information or (ii) delete Personal Information data from the App’s interface or (iii) terminate a Client’s account, an automated process will begin that permanently deletes the Client’s Personal Information in accordance with the timelines set forth in the tables below. Once begun, this process cannot be reversed and data will be permanently deleted, or data will be stripped from any personalized information and shall be kept in an anonymized manner.</li>
                                            </ol>
                                            <table border="1" cellspacing="0" cellpadding="0">
                                              <tbody>
                                                <tr>
                                                  <td width="109" valign="top">Type of Data</td>
                                                  <td width="457" valign="top"><b>Timeline for Deletion (after deletion process begins) for Cancellation, Termination or Migration</b></td>
                                                </tr>
                                                <tr>
                                                  <td width="109" valign="top">Backups</td>
                                                  <td width="457" valign="top">365 days</td>
                                                </tr>
                                                <tr>
                                                  <td width="109" valign="top">Logs</td>
                                                  <td width="457" valign="top">365 days</td>
                                                </tr>
                                              </tbody>
                                            </table>
                                            <p>Annex &#8211; A list of current subcontractors engaged with the Company</p>
                                            <p></p>
                                            <ol>
                                              <li>
                                                <span dir="LTR"></span>Third Parties Platforms used for the Service (updated Feb 2019)
                                                <ol>
                                                  <li><span dir="LTR"></span>Loggly</li>
                                                  <li><span dir="LTR"></span>SendWithUs</li>
                                                  <li><span dir="LTR"></span>MailGun</li>
                                                  <li><span dir="LTR"></span>MixPanel</li>
                                                  <li><span dir="LTR"></span>Google Analytics</li>
                                                  <li><span dir="LTR"></span>Microsoft Azure</li>
                                                  <li>Amazon Web Services</li>
                                                  <li><span dir="LTR"></span>Trio Hosting Services</li>
                                                  <li><span dir="LTR"></span>ICount</li>
                                                  <li><span dir="LTR"></span>ZCredit</li>
                                                  <li><span dir="LTR"></span>Freshdesk</li>
                                                  <li>Stripe</li>
                                                  <li>ChargeBee</li>
                                                  <li>Segment</li>
                                                  <li>AutoPilotHQ</li>
                                                </ol>
                                              </li>
                                            </ol>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    <Footer />
  </div>

  <script
    dangerouslySetInnerHTML={{
        __html: `
          jQuery(document).ready(function(){jQuery('#menu-item-92 a').addClass('elementor-button-link elementor-button').css('background-color','#C60051').css('color','white');});
          `
    }}
  />

  <button className="hestia-scroll-to-top">
      <i className="fa fa-angle-double-up" aria-hidden="true"></i>
  </button>

  <script
    dangerouslySetInnerHTML={{
        __html: `
        var requestpost={"ajaxurl":"https:\/\/outicks.com\/wp-admin\/admin-ajax.php","disable_autoslide":"","masonry":""};
        `
    }}
  />

  <script
    dangerouslySetInnerHTML={{
        __html: `
        var elementorFrontendConfig={"environmentMode":{"edit":false,"wpPreview":false},"is_rtl":"","breakpoints":{"xs":0,"sm":480,"md":768,"lg":1025,"xl":1440,"xxl":1600},"version":"2.4.6","urls":{"assets":"https:\/\/outicks.com\/wp-content\/plugins\/elementor\/assets\/"},"settings":{"page":[],"general":{"elementor_global_image_lightbox":"yes","elementor_enable_lightbox_in_editor":"yes"}},"post":{"id":44,"title":"Time Off Management","excerpt":""}};
        `
    }}
  />

  <script
    dangerouslySetInnerHTML={{
        __html: `
        COOKIES_ENABLER.init({scriptClass:'ginger-script',iframeClass:'ginger-iframe',acceptClass:'ginger-accept',disableClass:'ginger-disable',dismissClass:'ginger-dismiss',bannerClass:'ginger_banner-wrapper',bannerHTML:document.getElementById('ginger-banner-html')!==null?document.getElementById('ginger-banner-html').innerHTML:'<div className="ginger_banner bottom dialog dark ginger_container ginger_container--open">'+'<p className="ginger_message">'+'<!--googleoff: index-->By using this site, you agree to our updated <a href=\"/privacy-policy\">Privacy Policy<\/a> and our <a href=\"/terms-of-service\">Terms of Use<\/a>.<!--googleon: index-->'+'</p>'+'<a href="#" className="ginger_btn ginger-accept ginger_btn_accept_all">'+'Accept Cookie'+'<\/a>'+'<\/div>',cookieDuration:365000,eventScroll:false,scrollOffset:20,clickOutside:true,cookieName:'ginger-cookie',forceReload:false,iframesPlaceholder:true,iframesPlaceholderClass:'ginger-iframe-placeholder',iframesPlaceholderHTML:document.getElementById('ginger-iframePlaceholder-html')!==null?document.getElementById('ginger-iframePlaceholder-html').innerHTML:'<p><!--googleoff: index-->This website uses cookies.<!--googleon: index-->'+'<a href="#" className="ginger_btn ginger-accept">Accept Cookie</a>'+'<\/p>'});
        `
    }}
  />
</>   
)}

}
